import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";

export const Tunes = () => {
  const { darkMode } = useContext(GlobalContext);

  return (
    <div className={`page-container ${darkMode ? "dark-mode" : "light-mode"}`}>
      <div className="content-section">
        <p>
          Here's my{" "}
          <a
            href="https://open.spotify.com/user/yaexqey9b89kxurt125k8hlvp"
            target="_blank"
            rel="noreferrer"
            style={{
              borderBottom: "1px solid currentColor",
              paddingBottom: "2px",
            }}
          >
            Spotify
          </a>
        </p>

        <iframe
          title="Songs that make me feel like me"
          style={{
            borderRadius: "12px",
            marginTop: "2rem",
          }}
          src="https://open.spotify.com/embed/playlist/4CKGxt9eRfo4wtGSdKqleE"
          width="100%"
          height="380"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        />
      </div>
    </div>
  );
};
