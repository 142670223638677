import React, { createContext, useState, useEffect } from "react";

// Initial state with system theme detection
const getInitialTheme = () => {
  if (typeof window !== "undefined") {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }
  return false;
};

// Create context
export const GlobalContext = createContext({
  darkMode: false,
  toggleDarkMode: () => {},
});

// Provider component
export const GlobalProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(getInitialTheme());

  // Listen for system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => setDarkMode(e.matches);

    mediaQuery.addEventListener("change", handleChange);
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  const toggleDarkMode = () => {
    setDarkMode((prev) => !prev);
  };

  return (
    <GlobalContext.Provider
      value={{
        darkMode,
        toggleDarkMode,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
