import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";

export const About = () => {
  const { darkMode } = useContext(GlobalContext);

  return (
    <div className={`page-container ${darkMode ? "dark-mode" : "light-mode"}`}>
      <div className="content-section">
        {/* <h2>About</h2> */}
        <p>
          Building the manufacturing OS at{" "}
          <a
            href="https://doss.com"
            target="_blank"
            rel="noreferrer"
            style={{
              borderBottom: "1px solid currentColor",
              paddingBottom: "2px",
            }}
          >
            Doss
          </a>
        </p>
        <p>
          Investing (pre-seed/seed) in great founders at{" "}
          <a
            href="https://breakers.vc/"
            target="_blank"
            rel="noreferrer"
            style={{
              borderBottom: "1px solid currentColor",
              paddingBottom: "2px",
            }}
          >
            Breakers
          </a>
        </p>
        <p>Love scuba diving, golf, travel, cooking, hot sauce</p>
      </div>
    </div>
  );
};
