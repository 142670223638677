export const imageData = [
  {
    filename: "brunchvibe.jpeg",
    title: "Brat Brunch",
    caption: "Brunch vibes in Bratislava, Slovakia (2022)",
  },
  {
    filename: "whiskytasting.jpeg",
    title: "Whisky Tasting",
    caption: "Birthday whisky flight in Edinburgh (2022)",
  },
  {
    filename: "arthurs.JPG",
    title: "Arthur's Seat",
    caption: "Hiking Arthur's Seat in Edinburgh (2022)",
  },
  {
    filename: "roadtrip.jpeg",
    title: "Scotland Roadtrip",
    caption: "All around Scotland (2022)",
  },
  {
    filename: "zurich.jpeg",
    title: "Zürich",
    caption: "Evening in Zürich, Switzerland (2022)",
  },
  {
    filename: "emwater.JPG",
    title: "Port of St. Andrews",
    caption: "A brisk walk on the Port of St. Andrews (2022)",
  },
  {
    filename: "bratislava.jpeg",
    title: "Bratislava Castle",
    caption: "Exploring Bratislava, Slovakia (2022)",
  },
  {
    filename: "wineball.JPG",
    title: "Wine Ball",
    caption: "Edinburgh Wine Society Christmas Ball (2022)",
  },
  {
    filename: "schloss.jpeg",
    title: "Schloss",
    caption: "Schönbrunn Palace, Vienna (2022)",
  },
  {
    filename: "wien.jpeg",
    title: "Wien",
    caption: "Views in Vienna (2022)",
  },
  {
    filename: "vaduz.png",
    title: "Vaduz",
    caption: "Vaduz Castle, Liechtenstein (2022)",
  },
  {
    filename: "luzern.jpeg",
    title: "Luzern",
    caption: "Chapel Bridge in Luzern, Switzerland (2022)",
  },
  {
    filename: "pilatus.jpeg",
    title: "Mt. Pilatus",
    caption: "Summit of Mount Pilatus near Luzern, Switzerland (2022)",
  },
  {
    filename: "sargans.jpeg",
    title: "Sargans",
    caption: "Sargans Castle, Switzerland (2022)",
  },
  {
    filename: "firbush.jpeg",
    title: "Firbush",
    caption: "Firbush Point, Scotland (2022)",
  },
  {
    filename: "chilies.jpeg",
    title: "Chili Garden",
    caption: "Growing hot peppers (2022)",
  },
  {
    filename: "oslo.jpeg",
    title: "Oslo",
    caption: "Oslo Opera House, Norway (2022)",
  },
  {
    filename: "books.jpeg",
    title: "Books",
    caption: "Cozy corner in St. Andrews (2022)",
  },
  {
    filename: "scottishbfast.jpeg",
    title: "Scottish Breakfast",
    caption: "Enjoying the Scottish fare in Edi (2022)",
  },
  {
    filename: "standrews.jpeg",
    title: "St Andrews",
    caption: "St Andrews rules (2022)",
  },
  {
    filename: "edigardens.jpeg",
    title: "Edinburgh Gardens",
    caption: "Princes Street Gardens (2022)",
  },
  {
    filename: "tor.jpeg",
    title: "Brandenburg Gate",
    caption: "Brandenburg Gate, Berlin (2022)",
  },
  {
    filename: "lolla.jpg",
    title: "Lolla Friends",
    caption: "Lollapalooza Berlin Festival (2022)",
  },
  {
    filename: "lochness.jpeg",
    title: "Loch Ness",
    caption: "Searching for Nessie (2022)",
  },
  {
    filename: "subaqua.jpeg",
    title: "Crammond Island Dive",
    caption: "Diving with Edinburgh University (2022)",
  },
  {
    filename: "deanvillage.jpeg",
    title: "Dean Village",
    caption: "Historic Dean Village, Edinburgh (2022)",
  },
  {
    filename: "loquita.jpeg",
    title: "Loquita",
    caption: "Dinner at Loquita, Santa Barbara (2022)",
  },
  {
    filename: "crosslawn.jpeg",
    title: "Balboa walks",
    caption: "Visitors in Newport Beach (2022)",
  },
  {
    filename: "oregoncoast.JPG",
    title: "Oregon Coast",
    caption: "Pacific Northwest coastline (2022)",
  },
  {
    filename: "skydiving.JPG",
    title: "Skydiving",
    caption: "Skydiving in Seattle (2022)",
  },
  {
    filename: "sfcharcuterie.jpeg",
    title: "San Francisco",
    caption: "Charcuterie in SF (2022)",
  },
  {
    filename: "mailbox2.JPG",
    title: "Mailbox Peak",
    caption: "Hiking Mailbox Peak trail (2022)",
  },
  {
    filename: "seattle.JPG",
    title: "Seattle",
    caption: "Seattle skyline (2022)",
  },
  {
    filename: "mailbox.jpeg",
    title: "The Mailbox",
    caption: "View from Mailbox Peak (2022)",
  },
  {
    filename: "wug.JPG",
    title: "Wug",
    caption: "This is a wug. These are two ___. (2022)",
  },
  {
    filename: "ollie.jpeg",
    title: "Florence",
    caption: "Piazzale Michelangelo with Ollie (2022)",
  },
  {
    filename: "myfriendmyfriend.jpeg",
    title: "My Friend, My Friend",
    caption: "Our fav scammer from Italy (2022)",
  },
  {
    filename: "roma.jpeg",
    title: "Roma",
    caption: "Roman Colosseum at sunset (2022)",
  },
  {
    filename: "navigli.jpeg",
    title: "Navigli",
    caption: "Navigli district in Milan (2022)",
  },
  {
    filename: "skitrip.jpeg",
    title: "Breck",
    caption: "Skiing in Breckenridge, Colorado (2022)",
  },
  {
    filename: "miami.jpeg",
    title: "WashU takes Miami",
    caption: "Tech trip to Miami (2021)",
  },
  {
    filename: "kaapo.jpeg",
    title: "Kaapo Cafe",
    caption: "Coffee with Finnish barista champion in Helsinki (2021)",
  },
  {
    filename: "gentsinla.JPG",
    title: "LA",
    caption: "Gents in Los Angeles (2021)",
  },
  {
    filename: "kth.jpeg",
    title: "KTH",
    caption: "A tour of KTH Royal Institute of Technology (2021)",
  },
  {
    filename: "sthlm.jpeg",
    title: "Gamla Stan",
    caption: "The old town of Stockholm, Sweden (2021)",
  },
  {
    filename: "estonia.jpeg",
    title: "Estonia",
    caption: "Old Town Tallinn (2021)",
  },
  {
    filename: "hotsauce.JPG",
    title: "Glabsauce",
    caption: "The signature collection (2021)",
  },
];

export const GITHUB_RAW_URL =
  "https://raw.githubusercontent.com/zachglabman/site-images/main/recently";

export const getImageUrl = (filename) => `${GITHUB_RAW_URL}/${filename}`;
