import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const projects = [
  { name: "Exchanger", url: "https://exchanger.vercel.app" },
  { name: "Short Kings", url: "https://shortkings.vercel.app/" },
  { name: "TeamDiff", url: "https://www.teamdiff.xyz/" },
  { name: "Practicum", url: "https://www.practicum.us/" },
  {
    name: "Glabsauce",
    url: "https://zachglabman.notion.site/Glabsauce-3b6dcead47544e8192ac4e4be776e5d9?pvs=4",
  },
];

export const Projects = () => {
  const { darkMode } = useContext(GlobalContext);

  return (
    <div className={`page-container ${darkMode ? "dark-mode" : "light-mode"}`}>
      <div className="content-section">
        <List sx={{ maxWidth: 360, margin: "0 auto" }}>
          {projects.map((project) => (
            <ListItem key={project.name} disablePadding>
              <ListItemButton
                component="a"
                href={project.url}
                target="_blank"
                sx={{
                  textAlign: "center",
                  padding: "0.75rem",
                  "&:hover": {
                    backgroundColor: darkMode
                      ? "rgba(255,255,255,0.1)"
                      : "rgba(0,0,0,0.1)",
                  },
                }}
              >
                <ListItemText
                  primary={project.name}
                  sx={{
                    textAlign: "center",
                    "& .MuiTypography-root": {
                      fontSize: "clamp(1rem, 2.5vw, 1.25rem)",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};
