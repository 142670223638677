import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import { GlobalContext } from "../context/GlobalState";
import { imageData, getImageUrl } from "../data/imageData";

export const Recently = () => {
  const { darkMode } = React.useContext(GlobalContext);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)");
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const itemData = imageData.map((item) => ({
    ...item,
    img: getImageUrl(item.filename),
  }));

  const handleOpen = (index) => {
    setActiveStep(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getCols = () => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    return 3;
  };

  return (
    <div className={`page-container ${darkMode ? "dark-mode" : "light-mode"}`}>
      <div className="content-section">
        <div className="recently-container">
          <ImageList
            sx={{
              width: "100%",
              maxWidth: "1200px",
              height: "auto",
              margin: "0 auto",
              overflow: "hidden",
            }}
            cols={getCols()}
            gap={16}
          >
            {itemData.map((item, index) => (
              <ImageListItem
                key={item.img}
                onClick={() => handleOpen(index)}
                sx={{
                  overflow: "hidden",
                  borderRadius: "8px",
                  transition: "transform 0.3s ease",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <img
                  src={`${item.img}?w=400&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=400&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    aspectRatio: "16/9",
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={false}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: "#fff",
            zIndex: 2,
          }}
        >
          <CloseIcon />
        </IconButton>

        <div className="carousel-container">
          <Carousel
            index={activeStep}
            onChange={(index) => setActiveStep(index)}
            animation="slide"
            navButtonsAlwaysVisible
            autoPlay={false}
            navButtonsProps={{
              style: {
                backgroundColor: "rgba(255,255,255,0.3)",
                color: "#fff",
              },
            }}
            indicatorContainerProps={{
              style: {
                marginTop: "auto",
              },
            }}
          >
            {itemData.map((item, index) => (
              <Paper
                key={index}
                elevation={0}
                sx={{
                  backgroundColor: "transparent",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1.5rem",
                }}
              >
                <img
                  src={item.img}
                  alt={item.title}
                  className="carousel-image"
                />
                <div className="carousel-caption">{item.caption}</div>
              </Paper>
            ))}
          </Carousel>
        </div>
      </Dialog>
    </div>
  );
};
