import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const workPlaces = [
  { name: "Breakers", url: "https://breakers.vc/" },
  { name: "Ramp", url: "https://ramp.com/" },
  { name: "Taika", url: "https://taika.co/" },
  { name: "Tesla", url: "https://tesla.com/" },
  { name: "Anthos Capital", url: "https://anthoscapital.com/" },
];

export const Work = () => {
  const { darkMode } = useContext(GlobalContext);

  return (
    <div className={`page-container ${darkMode ? "dark-mode" : "light-mode"}`}>
      <div className="content-section">
        <List sx={{ maxWidth: 360, margin: "0 auto" }}>
          {workPlaces.map((place) => (
            <ListItem key={place.name} disablePadding>
              <ListItemButton
                component="a"
                href={place.url}
                target="_blank"
                sx={{
                  textAlign: "center",
                  padding: "0.75rem",
                  "&:hover": {
                    backgroundColor: darkMode
                      ? "rgba(255,255,255,0.1)"
                      : "rgba(0,0,0,0.1)",
                  },
                }}
              >
                <ListItemText
                  primary={place.name}
                  sx={{
                    textAlign: "center",
                    "& .MuiTypography-root": {
                      fontSize: "clamp(1rem, 2.5vw, 1.25rem)",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};
