import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { GlobalContext } from "./context/GlobalState";
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Recently } from "./components/Recently";
import { Work } from "./components/Work";
import { Tunes } from "./components/Tunes";
import { Projects } from "./components/Projects";
import lightChili from "./images/glabchili_lightmode.png";
import darkChili from "./images/glabchili_darkmode.png";
import backArrowLight from "./images/backarrow_light.png";
import backArrowDark from "./images/backarrow_dark.png";
import "./App.css";

// Back button component
const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { darkMode } = useContext(GlobalContext);

  // Only show back arrow on internal pages
  if (location.pathname === "/") return null;

  return (
    <img
      src={darkMode ? backArrowDark : backArrowLight}
      alt="Back to Home"
      className="backarrow"
      onClick={() => navigate("/")}
    />
  );
};

// Main layout component
const Layout = ({ children }) => {
  const { darkMode, toggleDarkMode } = useContext(GlobalContext);
  const chiliLogo = darkMode ? darkChili : lightChili;
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div id={darkMode ? "dark" : "light"}>
      <div className="header-container">
        {/* Center the chili logo with link functionality */}
        <div className="logo-container">
          <img
            src={chiliLogo}
            alt="Chili Logo"
            id="chili"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
        </div>

        {/* Dark mode toggle */}
        <div className="mode-switch">
          <input
            type="checkbox"
            checked={darkMode}
            onChange={toggleDarkMode}
            id="darkMode"
          />
        </div>
      </div>

      {/* Title container with back arrow for internal pages */}
      {location.pathname !== "/" && (
        <div className="title-container">
          <h2>
            <BackButton />
            {location.pathname.substring(1).charAt(0).toUpperCase() +
              location.pathname.slice(2)}
          </h2>
        </div>
      )}

      {children}
    </div>
  );
};

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/work" element={<Work />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/tunes" element={<Tunes />} />
          <Route path="/recently" element={<Recently />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
