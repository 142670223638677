import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../context/GlobalState";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import "../styles/Home.css";

export const Home = () => {
  const { darkMode } = useContext(GlobalContext);

  const text = "Hi, I'm Zach 👋";

  return (
    <div className={`page-container ${darkMode ? "dark-mode" : "light-mode"}`}>
      <div className="content-section">
        <h2>{text}</h2>
        <List>
          {["About", "Work", "Projects", "Tunes", "Recently"].map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton
                component={Link}
                to={`/${item.toLowerCase()}`}
                sx={{
                  textAlign: "center",
                  padding: "0.75rem",
                  "&:hover": {
                    backgroundColor: darkMode
                      ? "rgba(255,255,255,0.1)"
                      : "rgba(0,0,0,0.1)",
                  },
                }}
              >
                <ListItemText
                  primary={item}
                  sx={{
                    textAlign: "center",
                    "& .MuiTypography-root": {
                      fontSize: "clamp(1rem, 2.5vw, 1.25rem)",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};
